// @mui icons
// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/mainlogo.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Fluenz",
    image: logoCT,
    route: "/",
  },
  socials: [],
  menus: [],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Material Kit by {"Mohan"}.
    </MKTypography>
  ),
};
