//External Lib Import
import toast from "react-hot-toast";

class ToastMessage {
  static successMessage(msg) {
    return toast.success(msg, {
      duration: 4000,
      position: "top-center",
    });
  }
  static errorMessage(msg) {
  
    return toast.error(msg, {
      duration: 4000,
      position: "top-center",
    });
  }
  static LoadMessage(msg) {
    return toast.loading(msg, {
      duration: 4000,
      position: "top-center",
      
    });
  }
  static CustomMessage(msg) {
    return toast.success(msg, {
      duration: 4000,
      position: "top-center",
      style: {background:'#FF4D4A', color:'#ffff'},
    });
  }
}

export default ToastMessage;
