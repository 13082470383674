// dateUtils.js
export const getFormattedDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');

    // Adding suffix to the day
    const getDayWithSuffix = (day) => {
        if (day > 3 && day < 21) return `${day}th`; // 4th - 20th
        switch (day % 10) {
            case 1: return `${day}st`;
            case 2: return `${day}nd`;
            case 3: return `${day}rd`;
            default: return `${day}th`;
        }
    };

    const formattedDay = getDayWithSuffix(day);

    return `${formattedDay} ${month} ${year} ${hours}:${minutes}`;
};
