
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import EmailverifiedIMg from 'assets/images/emailverified.jpg'
// ----------------------------------------------------------------------
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from '@mui/material/styles';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 680,
  margin: 'auto',
  minHeight: '80vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const theme = createTheme({
  palette: {
    background: {
      default: 'white',
    },

  },
});

export default function Verified() {
  return (
    <>
    <ThemeProvider theme={theme}>
        <title> Verified </title>
     
      <Container sx={{background:'#fff',mt:2,borderRadius:5}}>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Successful Verification!
          </Typography >
        
          <Typography sx={{ color: 'text.secondary' }}>
          Email verification has been done!
          </Typography>
      
          <Typography sx={{ color: 'text.secondary' }}>
          Our team will get in touch with you via Email or SMS for further process
          </Typography>

          <Box
            component="img"
            src={EmailverifiedIMg}
            sx={{ height: 350}}
          />

          <Button to="/" size="large"  variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </StyledContent>
      </Container>
      </ThemeProvider>
    </>
  );
}
