import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import ToastMessage from "../../../../helpers/ToastMessage";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PinIcon from "@mui/icons-material/Pin";
import EmailIcon from "@mui/icons-material/Email";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import { useRef } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import successimg from "assets/images/successreg.jpg";
import otpimage from "assets/images/otp.avif";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import city from "data/city";
import Reaptcha from "reaptcha";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import isMobilePhone from "validator/lib/isMobilePhone";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import DangerousRoundedIcon from "@mui/icons-material/DangerousRounded";
import { createTheme } from "@mui/material/styles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useNavigate } from "react-router-dom";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function Enquiry() {
  // (B) BUILD URL
  const url = new URL(process.env.REACT_APP_API_URL + "/api/create");
  const theme = createTheme({
    components: {
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontWeight: 500,
            fontStyle: "italic",
          },
        },
      },
    },
  });

  const [invalid, setInvalid] = useState({
    emailerror: false,
    mobileerror: false,
    message: "",
    momessage: "",
  });

  const [require, setRequire] = useState(false);
  const [otpverified, setOtpverified] = useState(false);
  const [checkotp, setCheckotp] = useState(false);
  const [duplicateemail, setDuplicateemail] = useState(false);
  const [duplicatemobile, setDuplicatemobile] = useState(false);
  const [notduplicatemobile, setNotDuplciatemobile] = useState(false);
  const [notduplicateemail, setNotDuplciateemail] = useState(false);
  const [startvalidate_email, setStartvalidate_email] = useState(false);
  const [startvalidate_mob, setStartvalidate_mob] = useState(false);
  const [Wentwrong, setWentwrong] = useState(false);
  const [wkaoi, setAoi] = useState([]);
  const [wklinkedselect, setLinkedselect] = useState([]);
  const [otp, setOtp] = useState(false);
  const [Captchareset, setCaptchareset] = useState(false);
  const [errorotp, setErrorotp] = useState(false);
  const navigate = useNavigate()
  const [countdown, setCountdown] = useState(50);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (otp) {
      let timer;
      if (countdown > 0 && disabled) {
        timer = setInterval(() => {
          setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);
      } else if (countdown === 0 && disabled) {
        console.log("Time for resend");
        clearInterval(timer);
        setDisabled(false);
      }

      return () => {
        clearInterval(timer);
      };
    }
  }, [countdown, otp]);

  const Resendclicked = () => {
    setDisabled(true);
    GenerateOtp()

    setCountdown(400);
  };

  const [verification, setVerification] = useState(false);
  const [loader, setLoader] = useState(false);
  const [verified, setVerified] = useState(true);
  const [signupdetails, setSignupdetails] = useState({
    name: "",
    email: "",
    mobile: "",
    youtube: "",
    insta: "",
    linkedin: "",
    facebook: "",
    status: "Pending",
    city: [],
    otp: "",
  });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const names = [
    "Fashion & beauty",
    "Weddings & events",
    "D2C - E-commerce",
    "Latest trends",
    "Travel & lifestyle",
    "Motivational",
    "Technology & startups",
    "Gaming",
    "PETS",
    "Arts & Painting",
    "Sports & fitness",
    "Finance",
    "Dance & music",
    "Food",
    "Real estate",
    "Saas",
    "Enterprise ERP",
    "Others",
  ];

  const linkselect = ["Youtube", "Instagram", "Linkedin", "Facebook"];

  function handleChange(e) {
    if (e.target.name === "email") {
      if (!/\S+@\S+\.\S+/.test(e.target.value)) {
        setStartvalidate_email(false);

        setNotDuplciateemail(false);
        setDuplicateemail(false);
        setInvalid((prevState) => {
          return {
            ...prevState,
            emailerror: true,
            message: "Please Enter valid email",
          };
        });
      } else {
        setInvalid((prevState) => {
          return {
            ...prevState,
            emailerror: false,
            message: "",
          };
        });

        setStartvalidate_email(true);

        setNotDuplciateemail(false);
        setDuplicateemail(false);
        const fetchemail = async () => {
          // (B) BUILD URL
          const url3 = new URL(process.env.REACT_APP_API_URL + "/email/" + e.target.value);

          const options3 = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          };

          try {
            const response3 = await fetch(url3, options3);

            if (response3.status !== 421) {
              setStartvalidate_email(false);
              setNotDuplciateemail(false);
              setDuplicateemail(true);
              setInvalid((prevState) => {
                return {
                  ...prevState,
                  emailerror: true,
                  message: "Email Id is linked with verified Mobile Number! ",
                };
              });

              //  console.log('Went wrong')
            } else {
              //fetchemobile()
              setStartvalidate_email(false);
              setDuplicateemail(false);
              setNotDuplciateemail(true);
            }
          } catch (err) {
            // console.log('Went wrong' +err)
          }
        };

        setTimeout(() => {
          fetchemail();
        }, "2000");
      }
    }

    if (e.target.name === "mobile") {
      if (e.target.value.startsWith("0")) {
        // Invalid: Number should not start with "0"

        setStartvalidate_mob(false);

        setNotDuplciatemobile(false);
        setDuplicatemobile(false);
        setInvalid((prevState) => {
          return {
            ...prevState,
            mobileerror: true,
            momessage: "Please Don't Include '0' 0r '91'. Enter 10 Digit Mobile number",
          };
        });
      } else {
        if (isMobilePhone(e.target.value, "en-IN")) {
          setStartvalidate_mob(true);

          setNotDuplciatemobile(false);
          setDuplicatemobile(false);
          setInvalid((prevState) => {
            return {
              ...prevState,
              mobileerror: false,
              momessage: "",
            };
          });

          const fetchemobile = async () => {
            try {
              const url2 = new URL(process.env.REACT_APP_API_URL + "/mobile/" + e.target.value);

              const options2 = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
              };
              const response2 = await fetch(url2, options2);

              if (response2.status !== 421) {
                setStartvalidate_mob(false);
                setNotDuplciatemobile(false);
                setDuplicatemobile(true);

                setInvalid((prevState) => {
                  return {
                    ...prevState,
                    mobileerror: true,
                    momessage: "Mobile number Already Exists! ",
                  };
                });

                //  console.log('Went wrong')
              } else {
                setStartvalidate_mob(false);
                setDuplicatemobile(false);
                setNotDuplciatemobile(true);
              }
            } catch (err) {
              setLoader(false);
              setRequire(false);

              //     console.log('Went wrong mobile' + err)
            }
          };

          setTimeout(() => {
            fetchemobile();
          }, "2000");
        } else {
          setStartvalidate_mob(false);

          setNotDuplciatemobile(false);
          setDuplicatemobile(false);
          setInvalid((prevState) => {
            return {
              ...prevState,
              mobileerror: true,
              momessage: "Please Enter 10 Digit Mobile number",
            };
          });
        }
      }
    }

    if (e.target.name === "aoi" || e.target.name === "linkselect") {
      const {
        target: { value },
      } = e;
      if (e.target.name === "linkselect") {
        setLinkedselect(typeof value === "string" ? value.split(",") : value);
      } else if (e.target.name === "aoi") {
        setAoi(typeof value === "string" ? value.split(",") : value);
      }
    } else if (e.target.name === "city") {
      const {
        target: { value },
      } = e;

      setSignupdetails((prevState) => {
        return {
          ...prevState,
          [e.target.name]: typeof value === "string" ? value.split(",") : value,
        };
      });
    }

    {
      setSignupdetails((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  }

  const GenerateOtp = async () => {
    try {

      const url3 = new URL(process.env.REACT_APP_API_URL + "/otp/generateotp");
      const param = {
        mobile: signupdetails.mobile,
      };
      const options3 = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(param),
      };
      const response2 = await fetch(url3, options3);

      if (response2.status === 200) {
        ToastMessage.successMessage('OTP Sent Successfully');
      } else {
        setWentwrong(true);
      }
    } catch (err) {
      setLoader(false);
      setRequire(false);
      setWentwrong(true);
      //     console.log('Went wrong mobile' + err)
    }
  };

  const VerifyOtp = async (wotp) => {
    try {
      const url3 = new URL(process.env.REACT_APP_API_URL + "/otp/verifyotp");
      const param = {
        mobile: signupdetails.mobile,
        otp: wotp,
      };
      const options3 = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(param),
      };
      const response2 = await fetch(url3, options3);

      if (response2.status === 200) {
        setTimeout(() => {
          setOtpverified(true);
          setErrorotp(false);
          setCheckotp(false);
          navigate('/signup-success')
        }, 3000);

        //  console.log('Went wrong')
      } else {
        setOtpverified(false);
        setErrorotp(true);
        setCheckotp(false);
      }
    } catch (err) {
      setLoader(false);
      setRequire(false);
      setWentwrong(true);
      //     console.log('Went wrong mobile' + err)
    }
  };

  function sendVerificationMail() {
    const param = {
      email: signupdetails.email,
    };

    // (B) BUILD URL
    const url = new URL(process.env.REACT_APP_API_URL + "/sendmail");

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(param),
    };
    //   console.log(options)
    const sendMail = async () => {
      try {
        const response = await fetch(url, options);
        const newData = await response.json();

        if (newData.id === null || undefined) {
          setSentMail(true);

          //  console.log('Went wrong')
        } else {
          setSentMail(false);
        }
      } catch (err) {
        setLoader(false);
        setRequire(false);
        //     console.log('Went wrong Catch' + err)
      }
    };
    sendMail();
  }

  function handlesubmit1() {
    if (
      signupdetails.name !== "" &&
      signupdetails.email !== "" &&
      signupdetails.mobile !== "" &&
      wklinkedselect.length !== 0
    ) {
      setLoader(true);
      setRequire(false);

      //   setisLoading(true)
      const param = {
        name: signupdetails.name,
        email: signupdetails.email,
        aoi: wkaoi,
        youtube_link: signupdetails.youtube,
        facebook_link: signupdetails.facebook,
        insta_link: signupdetails.insta,
        linkedin_link: signupdetails.linkedin,
        mobile: signupdetails.mobile,
        status: "Pending",
        city: signupdetails.city,
      };

      // (B) BUILD URL
      const url = new URL(process.env.REACT_APP_API_URL + "/create");

      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(param),
      };
      //   console.log(options)
      const CreateData = async () => {
        try {
          const response = await fetch(url, options);
          const newData = await response.json();

          if (newData.id === null || undefined) {
            setWentwrong(true);
            setLoader(false);
            //  console.log('Went wrong')
          } else {
            sendVerificationMail();
            GenerateOtp();
            const timer = setTimeout(() => {
              setLoader(false);
              setOtp(true);
            }, 1000);
            return () => clearTimeout(timer);
          }
        } catch (err) {
          setLoader(false);
          setRequire(false);
          setWentwrong(true);
          //     console.log('Went wrong Catch' + err)
        }
      };
      CreateData();
    } else {
      if (
        (wklinkedselect.includes("Youtube") && signupdetails.youtube !== "") ||
        (wklinkedselect.includes("Facebook") && signupdetails.facebook !== "") ||
        (wklinkedselect.includes("Instagram") && signupdetails.insta !== "") ||
        (wklinkedselect.includes("Linkedin") && signupdetails.linkedin !== "")
      ) {
        setRequire(true);
      }
      setRequire(true);
    }
  }
  function customAnd(a, b) {
    return (a && b) || (!a && !b);
  }
  function handlesubmit() {
    if (
      signupdetails.name !== "" &&
      signupdetails.email !== "" &&
      signupdetails.mobile !== "" &&
      wklinkedselect.length !== 0 &&
      !invalid.emailerror &&
      !invalid.mobileerror &&
      customAnd(wklinkedselect.includes("Youtube"), signupdetails.youtube !== "") &&
      customAnd(wklinkedselect.includes("Facebook"), signupdetails.facebook !== "") &&
      customAnd(wklinkedselect.includes("Instagram"), signupdetails.insta !== "") &&
      customAnd(wklinkedselect.includes("Linkedin"), signupdetails.linkedin !== "")
    ) {
      setLoader(true);
      setRequire(false);

      handlesubmit1();
    } else {
      if (
        (wklinkedselect.includes("Youtube") && signupdetails.youtube !== "") ||
        (wklinkedselect.includes("Facebook") && signupdetails.facebook !== "") ||
        (wklinkedselect.includes("Instagram") && signupdetails.insta !== "") ||
        (wklinkedselect.includes("Linkedin") && signupdetails.linkedin !== "")
      ) {
        setRequire(true);
      }
      setRequire(true);
    }
  }

  function handleChangeotp(e) {
    if (e.target.value.length === 4) {
      setCheckotp(true);

      VerifyOtp(e.target.value);
    }
  }

  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaRef = useRef(null);

  const verify = () => {
    captchaRef.current.getResponse().then((res) => {
      // console.log(res)
      setCaptchaToken(res);
      const url1 = new URL(process.env.REACT_APP_API_URL + "/captchaverify");

      const options1 = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token: res }),
      };
      //  console.log(options1)

      const fetchData1 = async () => {
        try {
          const response = await fetch(url1, options1);
          const newData = await response.json();
          if (response.status === 200) {
            setVerified(false);
          }
        } catch {
          setLoader(false);
          setRequire(false);
          setCaptchareset(true);
          //    console.log('Went wrong')
        }
      };
      fetchData1();
    });
  };

  return (
    <>
      <Box boxShadow={8} borderradius={5} mt={5}>
        <Grid container component="main" sx={{ height: "auto" }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={3}
            md={6}
            sx={{
              backgroundImage:
                "url('https://img.freepik.com/free-vector/sign-concept-illustration_114360-125.jpg')",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "60rem",
            }}
          />
          {!verification && !loader && !otp && (
            <Grid item xs={12} sm={8} md={6} p={3}>
              <Avatar sx={{ m: 1, bgcolor: "secondary" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Join our waitlist & be the first to know about any updates
              </Typography>

              <Grid item pt={4}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  value={signupdetails.name}
                  id="name"
                  label="Full Name"
                  placeholder="Full Name"
                  name="name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleChange}
                  autoComplete="name"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  value={signupdetails.email}
                  helperText={invalid.message}
                  error={invalid.emailerror}
                  id="email"
                  label="Email Address"
                  name="email"
                  type={"email"}
                  placeholder="Email Address"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {startvalidate_email && <CircularProgress size={25} color="success" />}
                        {duplicateemail && <DangerousRoundedIcon fontSize="medium" color="error" />}
                        {notduplicateemail && (
                          <CheckCircleRoundedIcon fontSize="medium" color="success" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleChange}
                  autoComplete="email"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  value={signupdetails.mobile}
                  helperText={invalid.momessage}
                  error={invalid.mobileerror}
                  onChange={handleChange}
                  name="mobile"
                  label="Mobile"
                  type="text"
                  id="mobile"
                  placeholder="Mobile Number"
                  InputProps={{
                    inputProps: { maxLength: 10 },

                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneInTalkIcon />
                        <span style={{ marginLeft: 8 }}>+91</span>
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end">
                        {startvalidate_mob && <CircularProgress size={25} color="success" />}
                        {duplicatemobile && (
                          <DangerousRoundedIcon fontSize="medium" color="error" />
                        )}
                        {notduplicatemobile && (
                          <CheckCircleRoundedIcon fontSize="medium" color="success" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="tel"
                />
                <Stack>
                  <Autocomplete
                    sx={{ mt: 2, minHeight: 45, minWidth: 200 }}
                    multiple
                    options={city.map((option) => option.title)}
                    name="city"
                    onChange={(e, value) =>
                      setSignupdetails((prevState) => {
                        return {
                          ...prevState,
                          city: value,
                        };
                      })
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} name="city" label="Cities" placeholder="Search City" />
                    )}
                  />
                </Stack>
                <FormControl sx={{ mt: 2, minWidth: 200 }} fullWidth>
                  <InputLabel id="simple-select-label">* Select Social Media Profiles</InputLabel>
                  <Select
                    labelId="simple-select-label"
                    id="linksseelct"
                    name="linkselect"
                    label="* Select Social Media Profiles"
                    multiple
                    value={wklinkedselect}
                    onChange={handleChange}
                    displayEmpty
                    renderValue={(selected) => (
                      <Box
                        sx={{ minHeight: 20, p: 2, display: "flex", flexWrap: "wrap", gap: 0.5 }}
                      >
                        {selected.join(", ")}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    <MenuItem disabled value="">
                      <em>Select the Social Media you have</em>
                    </MenuItem>
                    {linkselect.map((linkselect) => (
                      <MenuItem key={linkselect} value={linkselect}>
                        <Checkbox checked={wklinkedselect.indexOf(linkselect) > -1} />
                        <ListItemText primary={linkselect} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {wklinkedselect.includes("Youtube") && (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    onChange={handleChange}
                    name="youtube"
                    label="Youtube Profile Link"
                    type="text"
                    id="youtube"
                    placeholder="Youtube  Profile link"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <YouTubeIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}

                {wklinkedselect.includes("Instagram") && (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    onChange={handleChange}
                    name="insta"
                    label="Insta Profile Link"
                    type="text"
                    id="insta"
                    placeholder="Instagram  Profile link"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <InstagramIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {wklinkedselect.includes("Linkedin") && (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    onChange={handleChange}
                    name="linkedin"
                    label="LinkedIn Profile Link"
                    type="text"
                    id="linkedin"
                    placeholder="LinkedIn Profile link"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LinkedInIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {wklinkedselect.includes("Facebook") && (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    onChange={handleChange}
                    name="facebook"
                    label="Facebook Profile Link"
                    type="text"
                    id="facebook"
                    placeholder="Facebook  Profile link"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FacebookIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                <div>
                  <FormControl sx={{ mt: 2, minWidth: 200 }} fullWidth>
                    <InputLabel id="aoilabel">Area of Interests</InputLabel>
                    <Select
                      label="Area of Interests"
                      labelId="aoilabel"
                      id="aoi"
                      name="aoi"
                      multiple
                      value={wkaoi}
                      onChange={handleChange}
                      displayEmpty
                      renderValue={(selected) => (
                        <Box
                          sx={{ minHeight: 20, p: 2, display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      <MenuItem disabled value="">
                        <em>Select all the Interests</em>
                      </MenuItem>
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Box pt={3}>
                    <Reaptcha
                      sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                      ref={captchaRef}
                      size={window.innerWidth <= 750 ? "compact" : "normal"}
                      onVerify={verify}
                      onExpire={() => captchaRef.current.reset()}
                      onError={() => captchaRef.current.reset()}
                    />
                  </Box>
                  {Captchareset && (
                    <Tooltip title="Reset Captcha to remove warnings" arrow>
                      <MKButton
                        variant="gradient"
                        color="warning"
                        onClick={() => captchaRef.current.reset()}
                      >
                        Reset Captcha
                      </MKButton>
                    </Tooltip>
                  )}
                </div>
                {require && (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    Please fill all the Required (*) Fields and clear the errors{" "}
                  </Alert>
                )}
                {Wentwrong && (
                  <Alert severity="warning" sx={{ mt: 2 }}>
                    Something Went Wrong Please try again after sometime!
                  </Alert>
                )}
                <MKButton
                  onClick={handlesubmit}
                  disabled={verified}
                  variant="gradient"
                  color="info"
                  type="submit"
                  sx={{ mt: 4, height: "100%" }}
                >
                  Submit for Verification
                </MKButton>
              </Grid>
            </Grid>
          )}

          {otp && !loader && (
            <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
              <Box
                sx={{
                  my: 10,
                  mx: 20,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary" }}>
                  <LockOutlinedIcon />
                </Avatar>

                {!otpverified && (
                  <>
                    <MKBox position="relative">
                      <MKBox
                        component="img"
                        src={otpimage}
                        sx={{
                          my: 10,
                          mx: 20,
                          width: "auto",
                          maxHeight: 250,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                        alt="Verify Mobile"
                      />
                    </MKBox>
                    <Typography>
                      OTP has been processed to{" "}
                      {signupdetails.mobile.replace(/(?<=\d{2}).(?=\d{3})/g, "x")}{" "}
                    </Typography>
                    <FormControl sx={{ mt: 2, minWidth: 250 }} fullWidth>
                      <TextField
                        margin="normal"
                        fullWidth
                        required
                        id="motp"
                        label="Mobile OTP "
                        type="number"
                        placeholder="Enter OTP received in Mobile"
                        name="motp"
                        InputProps={{
                          maxLength: 4,
                          startAdornment: (
                            <InputAdornment position="start">
                              <PinIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {checkotp && <CircularProgress size={25} color="success" />}
                              {errorotp && !checkotp && (
                                <DangerousRoundedIcon fontSize="medium" color="error" />
                              )}
                              {otpverified && (
                                <CheckCircleRoundedIcon fontSize="medium" color="success" />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleChangeotp}
                      />
                    </FormControl>

                    <MKButton
                      variant="gradient"
                      color="info"
                      onClick={Resendclicked}
                      disabled={disabled}
                    >
                      Resend OTP {countdown > 0 ? `(${countdown}s)` : ''}
                    </MKButton>
                  </>
                )}

                {otpverified && (
                  <>
                    <MKBox position="relative">
                      <MKBox
                        component="img"
                        src={successimg}
                        sx={{
                          my: 10,
                          mx: 20,
                          width: 250,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                        alt="Verify Email"
                      />
                    </MKBox>
                    <Typography component="h3" variant="h3">
                      Thanks for registering!
                    </Typography>
                    <Typography sx={{ width: "max-content" }} component="h6" variant="h6">
                      We will get back to you via Email or SMS
                    </Typography>
                  </>
                )}
              </Box>
            </Grid>
          )}

          {loader && (
            <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  my: 40,
                  mx: 20,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <MKBox position="relative">
                  <CircularProgress color="success" />
                </MKBox>
                <Typography component="h1" color="secondary">
                  Please wait...
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}
export default Enquiry;
