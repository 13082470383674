const city =[
  { title : 'Mumbai'},
{ title : 'Delhi'},
{ title : 'Bangalore'},
{ title : 'Hyderabad'},
{ title : 'Ahmedabad'},
{ title : 'Chennai'},
{ title : 'Kolkata'},
{ title : 'Surat'},
{ title : 'Pune'},
{ title : 'Jaipur'},
{ title : 'Lucknow'},
{ title : 'Kanpur'},
{ title : 'Nagpur'},
{ title : 'Indore'},
{ title : 'Thane'},
{ title : 'Bhopal'},
{ title : 'Visakhapatnam'},
{ title : 'Pimpri & Chinchwad'},
{ title : 'Patna'},
{ title : 'Vadodara'},
{ title : 'Ghaziabad'},
{ title : 'Ludhiana'},
{ title : 'Agra'},
{ title : 'Nashik'},
{ title : 'Faridabad'},
{ title : 'Meerut'},
{ title : 'Rajkot'},
{ title : 'Kalyan & Dombivali'},
{ title : 'Vasai Virar'},
{ title : 'Varanasi'},
{ title : 'Srinagar'},
{ title : 'Aurangabad'},
{ title : 'Dhanbad'},
{ title : 'Amritsar'},
{ title : 'Navi Mumbai'},
{ title : 'Allahabad'},
{ title : 'Ranchi'},
{ title : 'Haora'},
{ title : 'Coimbatore'},
{ title : 'Jabalpur'},
{ title : 'Gwalior'},
{ title : 'Vijayawada'},
{ title : 'Jodhpur'},
{ title : 'Madurai'},
{ title : 'Raipur'},
{ title : 'Kota'},
{ title : 'Guwahati'},
{ title : 'Chandigarh'},
{ title : 'Solapur'},
{ title : 'Hubli and Dharwad'},
{ title : 'Bareilly'},
{ title : 'Moradabad'},
{ title : 'Karnataka'},
{ title : 'Gurgaon'},
{ title : 'Aligarh'},
{ title : 'Jalandhar'},
{ title : 'Tiruchirappalli'},
{ title : 'Bhubaneswar'},
{ title : 'Salem'},
{ title : 'Mira and Bhayander'},
{ title : 'Thiruvananthapuram'},
{ title : 'Bhiwandi'},
{ title : 'Saharanpur'},
{ title : 'Gorakhpur'},
{ title : 'Guntur'},
{ title : 'Bikaner'},
{ title : 'Amravati'},
{ title : 'Noida'},
{ title : 'Jamshedpur'},
{ title : 'Bhilai Nagar'},
{ title : 'Warangal'},
{ title : 'Cuttack'},
{ title : 'Firozabad'},
{ title : 'Kochi'},
{ title : 'Bhavnagar'},
{ title : 'Dehradun'},
{ title : 'Durgapur'},
{ title : 'Asansol'},
{ title : 'Nanded Waghala'},
{ title : 'Kolapur'},
{ title : 'Ajmer'},
{ title : 'Gulbarga'},
{ title : 'Jamnagar'},
{ title : 'Ujjain'},
{ title : 'Loni'},
{ title : 'Siliguri'},
{ title : 'Jhansi'},
{ title : 'Ulhasnagar'},
{ title : 'Nellore'},
{ title : 'Jammu'},
{ title : 'Sangli Miraj Kupwad'},
{ title : 'Belgaum'},
{ title : 'Mangalore'},
{ title : 'Ambattur'},
{ title : 'Tirunelveli'},
{ title : 'Malegoan'},
{ title : 'Gaya'},
{ title : 'Jalgaon'},
{ title : 'Udaipur'},
{ title : 'Maheshtala'},
];

export default city;