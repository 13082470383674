import { useEffect, useState } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Toaster } from "react-hot-toast";
// Material Kit 2 React themes
import theme from "assets/theme";
import CreatorHome from "layouts/pages/landing-pages/Creator";
import BrandHome from "layouts/pages/landing-pages/Brand";

import SignIn from "layouts/pages/authentication/sign-in";
import AdminDash from "layouts/pages/landing-pages/AdminDash";
import Links from "layouts/pages/landing-pages/AdminDash/Links"
import CPCHome from "layouts/pages/landing-pages/AdminDash/cpc"


import PageNotFound from "layouts/pages/page404";
import EmailVerified from "pages/Verified";
import EmailUnVerified from "pages/Unverified";
// Material Kit 2 React routes
import routes from "routes";
import PostsHome from "layouts/pages/landing-pages/AdminDash/Posts";
import SignupSuccess from "pages/LandingPages/Creator/signup-success";

export default function App() {
  const { pathname } = useLocation();

  const { UserDetails } = useSelector((state) => state.User);
  const { AccessToken } = useSelector((state) => state.Auth);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Routes basename={process.env.REACT_PUBLIC_URL}>
        {getRoutes(routes)}
        <Route
          path="/dashboard"
          element={
            (AccessToken && UserDetails?.role === "WebAdmin") ? <AdminDash /> : <Navigate to="/admin" />
          }
        />

        <Route path="/creators_links" element={
          (AccessToken && UserDetails?.role === "WebAdmin") ? <Links /> : <Navigate to="/admin" />
        }
        />

        <Route path="/cpc_cpl" element={
          (AccessToken && UserDetails?.role === "WebAdmin") ? <CPCHome /> : <Navigate to="/admin" />
        }
        />
        <Route path="/campaings" element={
          (AccessToken && UserDetails?.role === "WebAdmin") ? <PostsHome /> : <Navigate to="/admin" />
        }
        />

        <Route
          path="/admin"
          element={
            (AccessToken && UserDetails?.role === "WebAdmin") ? <Navigate to="/dashboard" /> : <SignIn />
          }
        />

        <Route path="/CreatorHome" element={<CreatorHome />} />
        <Route path="/signup-success" element={<SignupSuccess />} />
        <Route path="/" element={<Navigate to="/CreatorHome" />} />

        <Route path="/BrandHome" element={<BrandHome />} />

        <Route path="/EmailVerified" element={<EmailVerified />} />
        <Route path="/Unverified" element={<EmailUnVerified />} />

        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />

      </Routes>
      <Toaster />
    </ThemeProvider>
  );
}
