//Internal Import

import { SetUserDetails } from "../redux/slice/UserSlice";
import ToastMessage from "../helpers/ToastMessage";
import { SetLogin } from "../redux/slice/AuthSlice";
import store from "../redux/store";
import RestClient from "./RestClient";

class AuthRequest {

  static async LoginAdmin(postBody) {
    const { data } = await RestClient.postRequest("/admin/checklogin", postBody);
 
    if (data) {
  
      store.dispatch(SetLogin(data?.AccessToken));
      store.dispatch(SetUserDetails(data?.UserDetails));
      ToastMessage.successMessage("Successfull Login");
      sessionStorage.clear();
      sessionStorage.setItem('logged_data', JSON.stringify(data?.UserDetails));
    }

  }

}

export default AuthRequest;
