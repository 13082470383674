import { filter } from 'lodash';
import { useEffect, useState } from 'react';

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme } from '@mui/material/styles';
import axios from 'axios';
import {
  Card,
  Alert,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  InputLabel,
  Box,
  CardMedia,
} from '@mui/material';
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { UserListHead, UserListToolbar } from './sections';
import { useConfirm } from "material-ui-confirm";
import AdminDataRequest from '../../../APIRequest/AdminDataRequest';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email / Mobile', alignRight: false },
  { id: 'unique_link', label: 'Unique Link ', alignRight: false },
  { id: 'post_name', label: 'Campaign Name ', alignRight: false },
  { id: 'post_image', label: 'Campaign Image ', alignRight: false },
  { id: 'post_url', label: ' ', alignRight: false },
  { id: 'clicks', label: 'Clicks', alignRight: false },

  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },

];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function Links() {

  const theme = createTheme({
    palette: {
      background: {
        admin: 'white',
      },

    },
  });

  const [open, setOpen] = useState(null);
  const [rerun, setRerun] = useState();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [loader, setLoader] = useState(false);
  const [scrabloader, setScarbLoader] = useState(false);
  const [Wentwrong, setWentwrong] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userlist, setUserlist] = useState([]);

  const [InstaMediadata, setInstaMediadata] = useState('')

  //fecth users:

  useEffect(() => {



    const fetchData = async () => {
      try {

        const newData = await AdminDataRequest.getlinks();

        if (newData.id === null || undefined) {
          setWentwrong(true);

        } else {

          setUserlist(newData)
          const timer = setTimeout(() => {

            //setVerification(true);
          }, 1000);
          return () => clearTimeout(timer);
        }

      }
      catch {
        setLoader(false)
        // setRequire(false);
        setWentwrong(true);

      }
    };
    fetchData();
  }, [rerun]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userlist.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleapprove = (e) => {

    const id1 = e.target.value
    const url1 = new URL(process.env.REACT_APP_API_URL + '/link/' + id1);
    const param1 = {
      status: 'Active',

    };
    const options1 = {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("AccessToken")
      },
      body: JSON.stringify(param1),
    };

    const updatedata = async () => {
      try {
        const response1 = await fetch(url1, options1);
        const newData1 = await response1.json();

        if (newData1.id === null || undefined) {
          setWentwrong(true);

        } else {
          setRerun(new Date().toLocaleTimeString())
          const timer = setTimeout(() => {

            //setVerification(true);
          }, 1000);
          return () => clearTimeout(timer);
        }

      }
      catch (error) {
        setLoader(false)
        //  setRequire(false);
        setWentwrong(true);

      }
    };
    updatedata();
    setOpen(false)
  }
  const handledecline = (e) => {

    const id2 = e.target.value;
    const url2 = new URL(process.env.REACT_APP_API_URL + '/link/' + id2);

    const param2 = {
      status: 'Disabled'
    };
    const options2 = {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("AccessToken")
      },
      body: JSON.stringify(param2),
    };

    const updatedata2 = async () => {
      try {
        const response2 = await fetch(url2, options2);
        const newData2 = await response2.json();

        if (newData2.id === null || undefined) {
          setWentwrong(true);

        } else {
          setRerun(new Date().toLocaleTimeString())

          const timer = setTimeout(() => {

            //setVerification(true);
          }, 1000);
          return () => clearTimeout(timer);
        }

      }
      catch (error) {
        setLoader(false)
        //  setRequire(false);
        setWentwrong(true);

      }
    };
    updatedata2();
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userlist.length) : 0;

  const filteredUsers = applySortFilter(userlist, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  useEffect(() => {
    if (InstaMediadata != '') {
      const valuesd = InstaMediadata.response.body.items;
      let sum3 = 0;

      for (const element of valuesd) {
        const sum = element.like_count
        const sum2 = element.comment_count
        sum3 = sum3 + (sum + sum2)

      }

      setInstaEpercent(((sum3 / 18) / parseInt(instascraber.response.body.data.user.edge_followed_by.count)) * 100)
    }
  }, [InstaMediadata])

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Container sx={{ maxWidth: '-webkit-fill-available !important' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Creators Unique Links
            </Typography>
          </Stack>

          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
            <Scrollbar>
              <TableContainer component={Paper} sx={{ minWidth: 800 }}>
                <Table sx={{ minWidth: 700 }}  >
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={userlist.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                      const selectedUser = selected.indexOf(row.id) !== -1;
                      //     console.log(row.creator_data[0])
                      return (
                        <TableRow hover key={row.id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, row.id)} />
                          </TableCell>

                          <TableCell id="name" component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={row.creator_data.name} />
                              <Typography sx={{ maxWidth: 200, wordWrap: "break-word" }} variant="subtitle2" >
                                {row.creator_data.name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left" sx={{
                            maxWidth: 200,
                            wordWrap: "break-word"
                          }}>{row.creator_data.email}  {row.creator_data.mobile}</TableCell>

                          <TableCell align="left" sx={{
                            maxWidth: 200,
                            wordWrap: "break-word"
                          }}>{row.unique_link} </TableCell>
                          <TableCell align="left" sx={{
                            maxWidth: 200,
                            wordWrap: "break-word"
                          }}>{row.post_data.name_campaign} </TableCell>
                          <TableCell align="left" sx={{
                            maxWidth: 200,
                            wordWrap: "break-word"
                          }}>

                            <MyComponent source={row.post_data.uploads} />

                          </TableCell>

                          <TableCell align="left" sx={{
                            maxWidth: 200,
                            wordWrap: "break-word"
                          }}>{row.click_count} </TableCell>

                          <TableCell align="left" sx={{ maxWidth: 200, wordWrap: "break-word" }}>
                            <Label color={(row.status === 'Disabled' && 'error') || (row.status === 'Active' && 'success') || 'success'}>
                              {row.status}
                            </Label>

                          </TableCell>

                          <>
                            <Button value={row._id} onClick={handleapprove.bind()}>
                              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} /> Enable  </Button>

                            <Button value={row._id} onClick={handledecline.bind()}>    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2, color: 'error.main' }} /> Disable </Button>
                          </>

                        </TableRow>

                      );

                    })}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={userlist.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </ThemeProvider>
    </>
  );
}

const MyComponent = ({ source }) => {

  const fileExtension = source ? source.split('.').pop().toLowerCase() : null;

  return (

    <Card>
      {fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'avif' || fileExtension === 'webp' ? (

        <CardMedia
          component="img"
          loading="lazy"
          image={process.env.REACT_APP_ACCOUNT_URL + '/uploaded/' + source}
          alt={source}
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
        />

      ) : fileExtension === 'mp4' || fileExtension === 'webm' || fileExtension === 'mkv' || fileExtension === 'mov' || fileExtension === 'mpeg4' ? (
        <CardMedia
          component="video"
          autoPlay
          loop
          muted
          loading="lazy"
          image={process.env.REACT_APP_ACCOUNT_URL + '/uploaded/' + source}
          alt={source}
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
        />

      ) : null}

    </Card>
  );
};

export default Links;
