import { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Reaptcha from 'reaptcha';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';

import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useRef } from "react";
import InputAdornment from '@mui/material/InputAdornment';

import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import successimg from "assets/images/successreg.jpg"
import verificationEmail from "assets/images/verificationEmail.avif"

import isMobilePhone from 'validator/lib/isMobilePhone';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import { createTheme } from '@mui/material/styles';

function Enquiry() {
  // (B) BUILD URL
  const url = new URL(process.env.REACT_APP_API_URL + "/api/create");
  const theme = createTheme({
    components: {

      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontWeight: 500,
            fontStyle: 'italic'

          }
        }
      }

    }
  })

  const [invalid, setInvalid] = useState({
    emailerror: false,
    mobileerror: false,
    message: '',
    momessage: ''
  });

  const [initialstate, setinitialstate] = useState({
    name: '',
    email: '',
    mobile: '',
    youtube: '',
    insta: '',
    linkedin: '',
    status: 'submitted'

  });
  const [require, setRequire] = useState(false)

  const [duplicateemail, setDuplicateemail] = useState(false)
  const [duplicatemobile, setDuplicatemobile] = useState(false)
  const [notduplicatemobile, setNotDuplciatemobile] = useState(false)
  const [notduplicateemail, setNotDuplciateemail] = useState(false)
  const [startvalidate_email, setStartvalidate_email] = useState(false)
  const [startvalidate_mob, setStartvalidate_mob] = useState(false)
  const [Wentwrong, setWentwrong] = useState(false)
  const [requireotpdata, setrequireotpdata] = useState(false)
  const [wkaoi, setAoi] = useState([]);
  const [wklinkedselect, setLinkedselect] = useState([]);
  const [otp, setOtp] = useState(false)
  const [otpdata, setOtpdata] = useState({
    motp: '',
    eotp: ''
  });
  const [verification, setVerification] = useState(false)
  const [loader, setLoader] = useState(false)
  const [verified, setVerified] = useState(true)
  const [brandsignupdetails, setBrandSignupdetails] = useState({
    company_name: '',
    email: '',
    mobile: '',
    status: 'Pending',
    contact_name: '',

  });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function handleChange(e) {

    if (e.target.name === 'email') {

      if (!(/\S+@\S+\.\S+/.test(e.target.value))) {
        setStartvalidate_email(false)

        setNotDuplciateemail(false);
        setDuplicateemail(false);
        setInvalid((prevState) => {
          return {
            ...prevState,
            emailerror: true,
            message: "Please Enter valid email",
          }
        }
        );

      } else {

        setInvalid((prevState) => {
          return {
            ...prevState,
            emailerror: false,
            message: "",
          }
        }
        );

        setStartvalidate_email(true)

        setNotDuplciateemail(false);
        setDuplicateemail(false);
        const fetchemail = async () => {

          // (B) BUILD URL
          const url3 = new URL(process.env.REACT_APP_API_URL + '/brand/email/' + e.target.value);

          const options3 = {
            method: "GET",
            headers: { 'Content-Type': 'application/json' },

          };

          try {
            const response3 = await fetch(url3, options3);

            if (response3.status !== 421) {
              setStartvalidate_email(false)
              setNotDuplciateemail(false);
              setDuplicateemail(true);
              setInvalid((prevState) => {
                return {
                  ...prevState,
                  emailerror: true,
                  message: "Email Id Already Exists! ",
                }
              }
              );

              //  console.log('Went wrong')
            } else {
              //fetchemobile()
              setStartvalidate_email(false)
              setDuplicateemail(false);
              setNotDuplciateemail(true);
            }

          }
          catch (err) {

            setWentwrong(true);
            // console.log('Went wrong' +err)
          }
        };

        setTimeout(() => {
          fetchemail();
        }, '2000');

      }
    }

    if (e.target.name === 'mobile') {

      if (isMobilePhone(e.target.value, "en-IN")) {
        setStartvalidate_mob(true)

        setNotDuplciatemobile(false);
        setDuplicatemobile(false);
        setInvalid((prevState) => {
          return {
            ...prevState,
            mobileerror: false,
            momessage: "",
          }
        }
        );

        const fetchemobile = async () => {
          try {
            const url2 = new URL(process.env.REACT_APP_API_URL + '/brand/mobile/' + e.target.value);

            const options2 = {
              method: "GET",
              headers: { 'Content-Type': 'application/json' },

            };
            const response2 = await fetch(url2, options2);

            if (response2.status !== 421) {
              //  setWentwrong(true);
              setStartvalidate_mob(false)
              setNotDuplciatemobile(false);
              setDuplicatemobile(true);

              setInvalid((prevState) => {
                return {
                  ...prevState,
                  mobileerror: true,
                  momessage: "Mobile number Already Exists! ",
                }
              }
              );

              //  console.log('Went wrong')
            } else {

              setStartvalidate_mob(false)
              setDuplicatemobile(false);
              setNotDuplciatemobile(true);
            }

          }
          catch (err) {
            setLoader(false)
            setRequire(false);
            setWentwrong(true);
            //     console.log('Went wrong mobile' + err)
          }
        };

        setTimeout(() => {
          fetchemobile()
        }, '2000');

      } else {
        setStartvalidate_mob(false)

        setNotDuplciatemobile(false);
        setDuplicatemobile(false);
        setInvalid((prevState) => {
          return {
            ...prevState,
            mobileerror: true,
            momessage: "Please Enter 10 Digit Mobile number",
          }
        }
        );

      }

    }

    setBrandSignupdetails((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value
      }
    }
    );

  }
  function sendVerificationMail() {

    const param = {
      email: brandsignupdetails.email,
    };

    // (B) BUILD URL
    const url = new URL(process.env.REACT_APP_API_URL + '/sendmail');

    const options = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(param)
    };
    //   console.log(options)
    const sendMail = async () => {
      try {
        const response = await fetch(url, options);
        const newData = await response.json();

        if (newData.id === null || undefined) {
          setSentMail(true);

          //  console.log('Went wrong')
        } else {
          setSentMail(false);
        }
      }
      catch (err) {
        setLoader(false)
        setRequire(false);
        setWentwrong(true);
        //     console.log('Went wrong Catch' + err)
      }
    };
    sendMail();
  }

  function handlesubmit1() {

    if (brandsignupdetails.contact_name !== '' && brandsignupdetails.email !== '' && brandsignupdetails.mobile !== '') {

      setLoader(true)
      setRequire(false);

      //   setisLoading(true)
      const param = {
        company_name: brandsignupdetails.company_name,
        contact_name: brandsignupdetails.contact_name,
        email: brandsignupdetails.email,
        mobile: brandsignupdetails.mobile,
        status: 'Pending',

      };

      // (B) BUILD URL
      const url = new URL(process.env.REACT_APP_API_URL + '/brand/create');

      const options = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(param)
      };
      //   console.log(options)
      const CreateData = async () => {
        try {
          const response = await fetch(url, options);
          const newData = await response.json();

          if (newData.id === null || undefined) {
            setWentwrong(true);
            setLoader(false)
            //  console.log('Went wrong')
          } else {
            sendVerificationMail()
            const timer = setTimeout(() => {
              setLoader(false)
              setOtp(true);
            }, 1000);
            return () => clearTimeout(timer);
          }

        }
        catch (err) {
          setLoader(false)
          setRequire(false);
          setWentwrong(true);
          //     console.log('Went wrong Catch' + err)
        }
      };
      CreateData();

    } else {

      setRequire(true);

    }
  }

  function handlesubmit() {

    if (brandsignupdetails.contact_name !== '' && brandsignupdetails.email !== '' && brandsignupdetails.mobile !== '' && !invalid.emailerror && !invalid.mobileerror) {

      setLoader(true)
      setRequire(false);

      handlesubmit1()

    } else {

      setRequire(true);

    }
  }

  function handleback() {
    setVerification(false);
    setBrandSignupdetails(initialstate);
    setAoi([]);
    setLinkedselect([]);
    setNotDuplciateemail(false);
    setNotDuplciatemobile(false);
  }
  function handleChangeotp(e) {
    setOtpdata((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value
      }
    }
    );
  }
  function handlesubmitotp(e) {
    e.preventDefault();

    setLoader(true)
    setrequireotpdata(false);
    const timer = setTimeout(() => {
      setLoader(false)
      setVerification(true);
      setOtp(false)
    }, 1000);
    return () => clearTimeout(timer);

  }

  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaRef = useRef(null);

  const verify = () => {
    captchaRef.current.getResponse().then(res => {
      // console.log(res)
      setCaptchaToken(res)
      const url1 = new URL(process.env.REACT_APP_API_URL + '/captchaverify');

      const options1 = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: res })
      };
      //  console.log(options1)

      const fetchData1 = async () => {
        try {
          const response = await fetch(url1, options1);
          const newData = await response.json();
          if (response.status === 200) {
            setVerified(false)
          }

        }
        catch {
          setLoader(false)
          setRequire(false);
          setWentwrong(true);
          //    console.log('Went wrong')
        }
      };
      fetchData1();

    })

  }

  return (
    <>
      <Box boxShadow={8} borderradius={5} mt={5}>
        <Grid container component="main" sx={{ height: "auto" }} alignItems="center"
          justifyContent="center">
          <CssBaseline />

          {!verification && !loader && !otp &&

            <Grid item xs={12} sm={8} md={6} p={3} alignItems="center"
              justifyContent="center">

              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Brand Signup
              </Typography>

              <Grid item pt={4}>

                <TextField
                  margin="normal"

                  required
                  fullWidth
                  id="company_name"
                  label="Company Name"

                  placeholder="Company Name"
                  name="company_name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleChange}
                  autoComplete="company name"
                  autoFocus
                />

                <TextField
                  margin="normal"

                  required
                  fullWidth
                  id="contact_name"
                  label="Company Contact Name"

                  placeholder="Company Contact Name"
                  name="contact_name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleChange}

                  autoFocus
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  helperText={invalid.message}
                  error={invalid.emailerror}
                  id="email"
                  label="Email Address"
                  name="email"
                  type={"email"}
                  placeholder="Email Address"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (<InputAdornment position="end">

                      {startvalidate_email && <CircularProgress size={25} color="success" />}
                      {duplicateemail && <DangerousRoundedIcon fontSize="medium" color="error" />}
                      {notduplicateemail && <CheckCircleRoundedIcon fontSize="medium" color="success" />}

                    </InputAdornment>)
                  }}
                  onChange={handleChange}
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  helperText={invalid.momessage}
                  error={invalid.mobileerror}
                  onChange={handleChange}
                  name="mobile"
                  label="Mobile"
                  type="text"
                  id="mobile"
                  placeholder="Mobile Number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneInTalkIcon />
                      </InputAdornment>
                    ),

                    endAdornment: (<InputAdornment position="end">

                      {startvalidate_mob && <CircularProgress size={25} color="success" />}
                      {duplicatemobile && <DangerousRoundedIcon fontSize="medium" color="error" />}
                      {notduplicatemobile && <CheckCircleRoundedIcon fontSize="medium" color="success" />}

                    </InputAdornment>)
                  }}

                  autoComplete="mobile"
                />
                <Box pt={3} >
                  <Reaptcha
                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                    ref={captchaRef}
                    size={window.innerWidth <= 750 ? 'compact' : 'normal'}
                    onVerify={verify}
                  />
                </Box>
                {require &&

                  <Alert severity="error" sx={{ mt: 2 }}>Please fill all the Required (*) Fields and clear the errors </Alert>

                }
                {Wentwrong &&

                  <Alert severity="warning" sx={{ mt: 2 }}>Something Went Wrong Please try again after sometime!</Alert>

                }
                <MKButton onClick={handlesubmit} disabled={verified} variant="gradient" color="info" sx={{ mt: 4, height: "100%" }}>
                  Submit for Verification
                </MKButton>

              </Grid>

            </Grid>
          }

          {otp && !loader &&

            <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
              <Box
                sx={{
                  my: 10,
                  mx: 20,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <LockOutlinedIcon />
                </Avatar>

                <MKBox position="relative">
                  <MKBox component="img" src={verificationEmail} sx={{
                    my: 10,
                    mx: 20,
                    width: 300,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                    alt="Verify Email" />
                </MKBox>

                <Typography sx={{
                  flexGrow: 1,
                  textAlign: "center"
                }} component="h1" variant="h5">
                  Verification Link has been Sent to the Mail id  {brandsignupdetails.email}.
                </Typography>

                <Typography sx={{ fontWeight: 'normal' }} component="h6" variant="h6" >
                  Please verify and click Done!
                </Typography>

                <Box component="form" noValidate sx={{ mt: 1 }}>

                  {/*             <TextField
                    margin="normal"
                    required
                    fullWidth
                    inputProps={{ inputMode: 'email' }}
                    onChange={handleChangeotp}
                    name="eotp"
                    label="Email OTP"
                    type="number"
                    id="email_otp"
                    placeholder="Enter the OTP received Via email"

                  />
            
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    inputProps={{ inputMode: 'numeric' }}
                    onChange={handleChangeotp}
                    name="motp"
                    label="Mobile OTP"
                    type="number"
                    id="mobile_otp"
                    placeholder="Enter the OTP received Via SMS to your mobile"

                  />

                    <Alert severity="error" sx={{ mt: 2 }}>Please Enter OTP of Mobile and Email!</Alert>

                  */}
                  <MKButton onClick={handlesubmitotp} variant="gradient" color="info" sx={{ mt: 4, height: "100%" }}>
                    Done
                  </MKButton>
                </Box>

              </Box>

            </Grid>
          }

          {loader &&

            <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"

                sx={{
                  my: 40,
                  mx: 20,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >

                <MKBox position="relative">
                  <CircularProgress color="success" />
                </MKBox>
                <Typography component="h1" color="secondary">
                  Please wait...
                </Typography>

              </Box>

            </Grid>

          }
          {!loader && verification &&

            <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
              <Box
                sx={{
                  my: 10,
                  mx: 20,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <CheckCircleIcon />
                </Avatar>

                <MKBox position="relative">
                  <MKBox component="img" src={successimg} sx={{
                    my: 10,
                    mx: 20,
                    width: 250,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                    alt="Verify Email" />
                </MKBox>
                <Typography component="h3" variant="h3" >
                  Thanks for registering!

                </Typography>
                <Typography sx={{ fontWeight: 'normal' }} component="h6" variant="h6" >
                  We Will get back to you via Email or SMS
                </Typography>
                <MKButton onClick={handleback} variant="gradient" color="info" sx={{ mt: 4, height: "100%" }}>
                  Go back!
                </MKButton>
              </Box>

            </Grid>

          }
        </Grid >
      </Box>
    </>
  );
}
export default Enquiry;
