import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Images
import grt from "assets/images/logos/grt.png";
import dra from "assets/images/logos/dra.png";
import tatasteel from "assets/images/logos/TATASTEEL.webp";
import third from "assets/images/logos/third.svg";
import casa from "assets/images/logos/casa.png";

function Featuring() {
  return (
    <MKBox boxShadow={8} borderradius={5} mt={5} pt={3} pb={3} bgColor="#f0bebd" >
      <Container>

        <Grid container spacing={5} justifyContent="space-between">
          <Grid item xs={6} md={4} mt={3} lg={2}>
            <MKBox component="img" src={grt} alt="grt" width="100%"  />
          </Grid>
          <Grid item xs={6} md={4} mt={3} lg={2}>
            <MKBox component="img" src={dra} alt="dra" width="100%"  />
          </Grid>
          <Grid item xs={6} md={4} mt={3} lg={2}>
            <MKBox component="img" src={tatasteel} alt="tatasteel" width="100%"  />
          </Grid>
          <Grid item xs={6} md={4} mt={10} lg={2}>
            <MKBox component="img" src={third} alt="third" width="100%"  />
          </Grid>
          <Grid item xs={6} md={4} mt={3} lg={2}>
            <MKBox component="img" src={casa} alt="casa" width="100%"  />
          </Grid>

        </Grid>

      </Container>
    </MKBox>
  );
}

export default Featuring;
