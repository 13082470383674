// @mui material components
import Icon from "@mui/material/Icon";

import CreatorHome from "layouts/pages/landing-pages/Creator";
import SignupSuccess from "pages/LandingPages/Creator/signup-success";
const routes = [
  {
    name: "Creator Home",
    route: "/CreatorHome",
    component: <CreatorHome />,
    icon: <Icon>dashboard</Icon>,
    collapse: false,
  },


];

export default routes;
