
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import UnverifiedImg from 'assets/images/unverified.png'
// ----------------------------------------------------------------------
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from '@mui/material/styles';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 780,
  margin: 'auto',
  minHeight: '80vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const theme = createTheme({
  palette: {
    background: {
      default: 'white',
    },

  },
});

export default function Unverified() {
  return (
    <>
    <ThemeProvider theme={theme}>
        <title> Not Verified </title>
     
      <Container sx={{background:'#fff',mt:2,borderRadius:5}}>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            OOPS... Email Verification Failed!
          </Typography>
        
          <Typography sx={{ color: 'text.secondary' }}>
          Email verification failed, possibly the link is invalid or expired
          </Typography>
       
          <Typography sx={{ color: 'text.secondary' }}>
          Our Team will get in touch with you via Email or SMS for further process
          </Typography>

          <Box
            component="img"
            src={UnverifiedImg}
            sx={{ height: 350, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />

          <Button to="/" size="large"  variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </StyledContent>
      </Container>
      </ThemeProvider>
    </>
  );
}
