//Internal Import
import ToastMessage from "../helpers/ToastMessage";

import RestClient from "./RestClient";

class AdminDataRequest {
  static async CreatorUpdate(postBody, id) {
    const { data } = await RestClient.updateRequest(`/admin/updatecreator/${id}`, postBody);
    if (data) {
      ToastMessage.successMessage("Creator updated Successfully");
    }
  }
  static async PostUpdate(postBody, id) {
    const { data } = await RestClient.updateRequest(`/brand/updatepost/${id}`, postBody);
    if (data) {
      ToastMessage.successMessage("Campaign updated Successfully");
    }
  }
  static async updatecpccpl(postBody, id) {
    const { data } = await RestClient.updateRequest(`/admin/updatecpccpl/${id}`, postBody);
    if (data) {
      ToastMessage.successMessage("CPC CPL updated Successfully");
    }
  }
  static async YoutubeScrabberUpdate(postBody) {
    const { data } = await RestClient.postRequest(`/admin/YoutubeScrabber`, postBody);
    if (data) {
      ToastMessage.successMessage("Youtube Data fetched Successfully");
    }
  }
  static async InstaScrabberUpdate(postBody) {
    const { data } = await RestClient.postRequest(`/admin/InstaScrabber`, postBody);
    if (data) {
      ToastMessage.successMessage("Insta  Data fetched Successfully");
    }
  }
  static async InstaMediaScrabberUpdate(postBody) {
    const { data } = await RestClient.postRequest(`/admin/InstaMediaScrabber`, postBody);
    if (data) {
      ToastMessage.successMessage("InstaMedia fetched Successfully");
    }
  }
  static async LinkedinScrabberUpdate(postBody) {
    const { data } = await RestClient.postRequest(`/admin/LinkedinScrabber`, postBody);
    if (data) {
      ToastMessage.successMessage("Linkedin Scrabbed Data updated Successfully");
    }
  }
  static async getcpcdetails(postBody) {
    const { data } = await RestClient.getRequest(`/admin/cpcdetails`, postBody);
    if (data) {
      return data;
    }
  }

  static async getallcampaigns(params) {
    const { data } = await RestClient.getRequest(`/admin/getallcampaigns`, params);
    if (data) {
      return data;
    }
  }

  static async getlinks(params) {
    const { data } = await RestClient.getRequest(`/admin/getlinks`, params);
    if (data) {
      return data;
    }
  }
  static async getlist(params) {
    const { data } = await RestClient.getRequest(`/getlist`, params);
    if (data) {
      return data;
    }
  }
}

export default AdminDataRequest;
