//External Lib Import
import { createSlice } from "@reduxjs/toolkit";
import SessionHelper from "../../helpers/SessionHelper";

const CreatorDataSlice = createSlice({
  name: "CreatorData",
  initialState: {
    LinkData: [],
  },
  reducers: {

    SetLinkdata(state, action) {
      state.LinkData = action.payload
    },

  },
});

export const {
  SetLinkdata,
} = CreatorDataSlice.actions;
export default CreatorDataSlice.reducer;
