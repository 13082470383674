import React, { useEffect, useMemo, useState } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";

import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AdminDataRequest from "../../../APIRequest/AdminDataRequest";
import { Button, Card, CardMedia, } from "@mui/material";
import { getFormattedDate } from "helpers/dateformat";
import Iconify from "components/iconify";
import Label from "components/label";

export default function App() {

  const [tabledata, setTabledata] = useState([]);
  const fetchData = async () => {
    try {
      const result = await AdminDataRequest.getallcampaigns();
      console.log("re", result);
      setTabledata(result); // Assuming result is an array of campaign objects
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    // Close modal
    setModalOpen(false);
  };

  const handleapprove = (e) => {

    const id1 = e.target.value;

    AdminDataRequest.PostUpdate(
      {
        post_status: "Active"
      },
      id1
    );

    setTimeout(() => {
      fetchData();
    }, 2000)


  };
  const handledecline = (e) => {
    const id2 = e.target.value;
    AdminDataRequest.PostUpdate(
      {
        post_status: "Rejected"
      },
      id2
    );
    setTimeout(() => {
      fetchData();
    }, 2000)

  };

  const handleConfirmation = () => {
    // Call update API with the id
    // Make sure to include the logic to call your update API with the id
    handleClose();
  };

  const theme = createTheme({
    palette: {
      background: {
        admin: "white"
      }
    }
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "name_campaign",
        header: "name_campaign  "
      },
      {
        accessorKey: "about",
        header: "about  "
      },
      {
        accessorKey: "category",
        header: "category  "
      },
      {
        accessorKey: "city",
        header: "city  ",
        Cell: ({ renderedCellValue }) => (
          renderedCellValue.join(', ')
        )
      },
      {
        accessorKey: "uploads",
        header: "uploads  ",
        Cell: ({ renderedCellValue, row }) => {
          const fileExtension = renderedCellValue
            ? renderedCellValue.split(".").pop().toLowerCase()
            : null;


          return (
            <Card>
              {fileExtension === "jpg" ||
                fileExtension === "jpeg" ||
                fileExtension === "png" ||
                fileExtension === "avif" ||
                fileExtension === "webp" ? (
                <CardMedia
                  component="img"
                  loading="lazy"
                  image={process.env.REACT_APP_API_URL + "/uploads/" + renderedCellValue}
                  alt={renderedCellValue}
                  sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                />
              ) : fileExtension === "mp4" ||
                fileExtension === "webm" ||
                fileExtension === "mkv" ||
                fileExtension === "mov" ||
                fileExtension === "mpeg4" ? (
                <CardMedia
                  component="video"
                  autoPlay
                  loop
                  muted
                  loading="lazy"
                  image={process.env.REACT_APP_API_URL + "/uploads/" + renderedCellValue}
                  alt={renderedCellValue}
                  sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                />
              ) : null}
            </Card>
          );
        }
      },
      {
        accessorKey: "post_status",
        header: "post_status  ",
        Cell: ({ renderedCellValue, row }) => (
          <Label
            color={
              (renderedCellValue === "Rejected" && "error") ||
              (renderedCellValue === "Pending" && "info") ||
              "success"
            }
          >
            {renderedCellValue}
          </Label>
        )
      },
      {
        accessorKey: "initial_budget",
        header: "initial_budget  "
      },
      {
        accessorKey: "remaining_budget",
        header: "remaining_budget  "
      },
      {
        accessorKey: "createdAt",
        header: "createdAt  ",
        Cell: ({ renderedCellValue, row }) => (

          getFormattedDate(renderedCellValue)
        ),
      }
    ],
    []
  );

  const table = useMantineReactTable({
    data: tabledata,
    columns,
    enableColumnFilterModes: true,
    getRowId: (originalRow) => originalRow.id,
    enableRowActions: true,

    renderRowActions: ({ row, table }) => (
      <>
        <Button value={row.id} onClick={handleapprove.bind()}>
          <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} /> Activate{" "}
        </Button>

        <Button value={row.id} onClick={handledecline.bind()}>
          {" "}
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2, color: "error.main" }} /> Reject{" "}
        </Button>
      </>
    )
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <MantineReactTable table={table} />
    </ThemeProvider>
  );
}
