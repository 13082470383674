// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from '@mui/material/CardMedia';
// Material Kit 2 React components
import MKBox from "components/MKBox";
// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// Brand  page sections
import Information from "pages/LandingPages/Brand/sections/Information";
import Featuring from "pages/LandingPages/Brand/sections/Featuring";
import Enquiry from "pages/LandingPages/Brand/sections/Enquiry";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgVideo from "assets/images/updatedVideo.mp4";
import bgImage from "assets/images/bg-about-us.jpg"
import SimpleFooter from "examples/Footers/SimpleFooter";

function Brand() {

  return (
    <>
      <DefaultNavbar
        routes={routes}
        relative
      />

      <MKBox
       
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",

          placeItems: "center",
        }}
      >
   
          <Grid item>
            <CardMedia
              display="flex"
              component='video'
              borderradius={3}
              sx={{
                maxWidth: '95%',
                borderRadius: 3,
                boxShadow: 2,
                mb: 5,
              }}
              image=''
              controls=''
              autoPlay
              loop
              muted
              
              controlsList="nofullscreen nodownload noremoteplayback noplaybackrate foobar"
              playsInline
              
            />

          </Grid>
        
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -2,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
       
        <Enquiry />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <SimpleFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Brand;
