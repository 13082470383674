import MKBox from "components/MKBox";

import Image1 from "assets/images/how1.png"
import Image2 from "assets/images/how2.png"
import Image3 from "assets/images/how3.png"
import Image4 from "assets/images/how4.png"
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const images = [
  {
    src: Image1,
  },
  {
    src: Image2,
  },
  {
    src: Image3,
  },
  {
    src: Image4,
  },
]
function Information() {
  return (

    <MKBox component="section"  >
      <Typography variant="h2" sx={{ margin: 2, marginLeft: 2, }} component="h2">
        How it works!
      </Typography>
      <Grid sx={{ flexGrow: 1 }} container spacing={4}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={6}>
            {images.map((value) => (
              <Grid key={value} item>
                <Paper elevation={4}
                  sx={{
                    minHeight: 330,
                    minWidth: 330,
                    marginTop: 2,

                    backgroundColor: (theme) =>
                      theme.palette.mode === 'dark' ? '#1A2027' : '#1A2027',
                    backgroundImage: `url(${value.src})`,
                    backgroundSize: 'cover',
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

    </MKBox>
  );

}

export default Information;
