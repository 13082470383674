// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from '@mui/material/CardMedia';
// Material Kit 2 React components
import MKBox from "components/MKBox";
// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// Creator  page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgVideo from "assets/images/updatedVideo.mp4";
import bgImage from "assets/images/bg-about-us.jpg"
import SimpleFooter from "examples/Footers/SimpleFooter";
import { Typography } from "@mui/material";
import successimg from "assets/images/successreg.jpg";
function SignupSuccess() {

    return (
        <>
            <DefaultNavbar
                routes={routes}
                relative
            />

            <div style={{ alignItems: 'center', display: 'block ruby', textAlign: 'center' }}>
                <Card
                    sx={{
                        p: 2,
                        minWidth: '50%',
                        maxWidth: '70%',
                        mt: 2,
                        mb: 4,
                        boxShadow: ({ boxShadows: { xxl } }) => xxl,
                        alignItems: 'center'
                    }}
                >
                    <>
                        <MKBox position="relative">
                            <MKBox
                                component="img"
                                src={successimg}
                                sx={{

                                    width: 250,

                                }}
                                alt="Verify Email"
                            />
                        </MKBox>
                        <Typography component="h3" variant="h3">
                            Thanks for registering!
                        </Typography>
                        <Typography sx={{ width: "max-content", alignItems: 'center', textAlign: 'center' }} component="h6" variant="h6">
                            We will get back to you via Email or SMS
                        </Typography>
                    </>
                </Card>
            </div>

        </>
    );
}

export default SignupSuccess;
