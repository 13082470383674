import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import city from "data/city";

import { Button } from "@mui/material";

import AdminDataRequest from "../../../../APIRequest/AdminDataRequest";
import Facebook from "@mui/icons-material/Facebook";

const EditCreator = ({ data, onUpdate }) => {

    const [wkaoi, setAoi] = useState([]);
    useEffect(() => {
        setAoi(data.aoi)
    }, [])

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const names = [
        'Fashion & beauty',
        'Weddings & events',
        'D2C - E-commerce',
        'Latest trends',
        'Travel & lifestyle',
        'Motivational',
        'Technology & startups',
        'Gaming', 
 'PETS',
 'Arts & Painting',
        'Sports & fitness',
        'Finance',
        'Dance & music',
        'Food',
        'Real estate',
        'Saas',
        'Enterprise ERP',

        'Others',
    ];

    function handleChange(e) {

        if (e.target.name === 'aoi') {
            const {
                target: { value },
            } = e;

            setAoi(
                typeof value === 'string' ? value.split(',') : value,
            );

        } else if (e.target.name === 'city') {
            const {
                target: { value },
            } = e;

            setSignupdetails((prevState) => {
                return {
                    ...prevState,
                    [e.target.name]: typeof value === 'string' ? value.split(',') : value,
                }
            }

            );
        }

        else {
            setSignupdetails((prevState) => {
                return {
                    ...prevState,
                    [e.target.name]: e.target.value
                }
            }
            );
        }
    }

    function handlesubmit1() {

        AdminDataRequest.CreatorUpdate({
            aoi: wkaoi,
        }, data.id)
        onUpdate()
    }

    return (
        <>

            <Grid item xs={12} sm={8} md={6} p={3}>

                <Grid item>

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        value={data.name}
                        readonly
                        label="Full Name"
                        placeholder="Full Name"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}

                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        value={data.email}
                        readonly
                        label="Email Address"

                        type={"email"}
                        placeholder="Email Address"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            ),

                        }}

                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Mobile"
                        type="text"
                        value={data.mobile}
                        readonly
                        placeholder="Mobile Number"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PhoneInTalkIcon />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField
                        sx={{ mt: 2, minHeight: 45, minWidth: 200 }}

                        value={data.city}
                        readonly
                        disabled
                        options={city.map((option) => option.title)}
                        name='city'

                        disableCloseOnSelect

                        fullWidth

                    />

                    {data.youtube_link != '' &&
                        <TextField
                            margin="normal"

                            fullWidth

                            name="youtube"
                            label="Youtube Profile Link"
                            type="text"
                            id="youtube"
                            value={data.youtube_link}
                            placeholder="Youtube Profile link"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <YouTubeIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    }

                    {data.insta_link != '' &&
                        <TextField
                            margin="normal"

                            fullWidth
                            value={data.insta_link}
                            name="insta"
                            label="Insta Profile Link"
                            type="text"
                            id="insta"
                            placeholder="Instagram Profile link"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <InstagramIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    }
                    {data.facebook_link != '' &&
                        <TextField
                            margin="normal"

                            fullWidth
                            value={data.facebook_link}
                            name="facebook"
                            label="Facebook Profile Link"
                            type="text"
                            id="facebook"
                            placeholder="Facebook Profile link"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Facebook />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    }
                    {data.linkedin_link != '' &&
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            value={data.linkedin_link}
                            name="linkedin"
                            label="Linked Profile Link"
                            type="text"
                            id="linkedin"
                            placeholder="Linkedin Profile link"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LinkedInIcon />
                                    </InputAdornment>
                                ),
                            }}

                        />
                    }
                    <div>
                        <FormControl sx={{ mt: 2, minWidth: 200 }} fullWidth>
                            <InputLabel id="aoilabel">Area of Interests</InputLabel>
                            <Select
                                label="Area of Interests"
                                labelId="aoilabel"
                                id="aoi"
                                name="aoi"
                                multiple
                                value={wkaoi}
                                onChange={handleChange}
                                displayEmpty
                                renderValue={(selected) => (
                                    <Box sx={{ minHeight: 20, p: 0, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}

                            >
                                <MenuItem disabled value="">
                                    <em>Select all the Interests</em>
                                </MenuItem>
                                {names.map((name) => (
                                    <MenuItem
                                        key={name}
                                        value={name}
                                    >
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box sx={{ mt: 2 }}>

                            <Button onClick={handlesubmit1} fullWidth size="large" type="submit" variant="contained" color="primary">
                                Update Creator
                            </Button>

                        </Box>
                    </div>

                </Grid>

            </Grid>

        </>
    );
}
export default EditCreator;
