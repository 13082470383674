
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import Image404 from 'assets/illustrations/gif_404.gif'
// ----------------------------------------------------------------------
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from '@mui/material/styles';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '80vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const theme = createTheme({
  palette: {
    background: {
      default: 'white',
    },

  },
});

export default function Page404() {
  return (
    <>
    <ThemeProvider theme={theme}>
        <title> 404 Page Not Found | Minimal UI </title>
     
      <Container sx={{background:'#fff',mt:2,borderRadius:5}}>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Sorry, page not found!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your
            spelling.
          </Typography>

          <Box
            component="img"
            src={Image404}
            sx={{ height: 350, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />

          <Button to="/" size="large"  variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </StyledContent>
      </Container>
      </ThemeProvider>
    </>
  );
}
