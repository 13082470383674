import React, { useEffect, useMemo, useState } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { MantineProvider } from "@mantine/core";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AdminDataRequest from "../../../APIRequest/AdminDataRequest";

export default function App() {
  const [tabledata, setTabledata] = useState([]);

  const fetchData = async () => {
    try {
      const result = await AdminDataRequest.getcpcdetails();
      setTabledata(result); // Assuming result is an array of campaign objects
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSaveRow = async ({ table, row, values }) => {
    // Update the 'cpl_lead' and 'cpc_value' fields, maintaining the $numberDecimal format
    const updatedRow = {
      ...tabledata[row.index],
      cpl_lead: row.original.cpl_lead,

      cpc_value: row.original.cpc_value,

    };

    // Update the row in your table data
    const updatedTableData = [...tabledata];
    updatedTableData[row.index] = updatedRow;

    // Optionally, send/receive API updates here
    try {
      const result = await AdminDataRequest.updatecpccpl(updatedRow, updatedRow.id);
      setTabledata(result); // Assuming result is an array of campaign objects
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    // Update state with the new table data
    setTabledata(updatedTableData);

    // Exit editing mode
    table.setEditingRow(null);
  };

  const theme = createTheme({
    palette: {
      background: {
        admin: "white",
      },
    },
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "platform",
        header: "Platform",
        muiTableHeadCellProps: { sx: { color: "green" } },
        mantineEditTextInputProps: null,
        enableEditing: false,
      },
      {
        accessorKey: "range_value",
        header: "Range Value",
        mantineEditTextInputProps: null,
        enableEditing: false,
      },
      {
        header: "Cost Per Click",
        accessorKey: "cpc_value",
        Cell: ({ renderedCellValue }) => {
          const value = renderedCellValue?.$numberDecimal ? parseFloat(renderedCellValue.$numberDecimal) : 0;
          return value.toFixed(2);
        },
        mantineEditTextInputProps: (cell) => ({
          type: 'number',
          value: cell.row.original.cpc_value?.$numberDecimal ? parseFloat(cell.row.original.cpc_value.$numberDecimal) : '',
          onChange: (e) => {
            const newValue = e.target.value;
            const updatedRow = { ...cell.row.original };
            updatedRow.cpc_value.$numberDecimal = newValue.toString();
            const updatedData = [...tabledata];
            updatedData[cell.row.index] = updatedRow;
            setTabledata(updatedData);
          },
        }),
      },
      {
        header: "Cost Per Lead",
        accessorKey: "cpl_lead",
        Cell: ({ renderedCellValue }) => {
          const value = renderedCellValue?.$numberDecimal ? parseFloat(renderedCellValue.$numberDecimal) : 0;
          return value.toFixed(2);
        },
        mantineEditTextInputProps: (cell) => ({
          type: 'number',
          value: cell.row.original.cpl_lead?.$numberDecimal ? parseFloat(cell.row.original.cpl_lead.$numberDecimal) : '',
          onChange: (e) => {
            const newValue = e.target.value;
            const updatedRow = { ...cell.row.original };
            updatedRow.cpl_lead.$numberDecimal = newValue.toString();
            console.log('updatedRow', updatedRow)
            const updatedData = [...tabledata];
            updatedData[cell.row.index] = updatedRow;
            setTabledata(updatedData);
          },
        }),
      }
    ],
    [tabledata]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MantineProvider>
        <MantineReactTable
          columns={columns}
          data={tabledata}
          editDisplayMode="row"
          enableEditing
          onEditingRowSave={handleSaveRow}
        />
      </MantineProvider>
    </ThemeProvider>
  );
}
