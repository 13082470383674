// component
import SvgColor from '../../../components/svg-color';
import LinkIcon from '@mui/icons-material/Link';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
 
  {
    title: 'Creators',
    path: '/dashboard',
    icon: <AccountBoxIcon />,
  },
  {
    title: 'Campaigns',
    path: '/campaings',
    icon: <AccountBoxIcon />,
  },
  {
    title: 'Creator Links',
    path: '/creators_links',
    icon: <LinkIcon />,
  },
   {
    title: 'CPC and CPL ',
    path: '/cpc_cpl',
    icon: <LinkIcon />,
  },
 
];

export default navConfig;
