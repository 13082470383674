//External Lib Import
import { configureStore } from "@reduxjs/toolkit";

//Internal Import
import AuthSlice from "../slice/AuthSlice";
import CreatorDataSlice from "../slice/CreatorDataSlice";
import UserSlice from "../slice/UserSlice";

const store = configureStore({
  reducer: {
    Auth : AuthSlice,
    Creator:CreatorDataSlice,
    User : UserSlice,
  },
});

export default store;
