// Material Kit 2 React pages
import AdminDash from "pages/LandingPages/AdminDash";
import Header from '../../../dashboard/header';
import Nav from '../../../dashboard/nav';
// @mui
import { styled } from '@mui/material/styles';

import { useState } from "react";
const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const Styleddiv = styled('div')(({ theme }) => ({
  display: '-webkit-box',
}));

export default function AdminDashHome() {
  const [open, setOpen] = useState(false);
  return (
  <>
  <Styleddiv>
    <Header onOpenNav={() => setOpen(true)} />
    <Nav openNav={open} onCloseNav={() => setOpen(false)} />
    <Main>
    <AdminDash />
    </Main>
    </Styleddiv>
    </>
  );
  
}
